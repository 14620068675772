<template>
    <div class="header-wrap fixed" :class="{'active':header_fixed&&screenWidth>1440,'mobile':screenWidth<=1440}">
        <div class="header">
            <img src="@/assets/logo-active.png" alt="" v-if="header_fixed && screenWidth>1440" class="logo"  @click="goNav('/')">
            <img src="@/assets/logo.png" alt="" class="logo" v-else>
            <ul v-if="screenWidth>1440">
                <li :class="{'active':nav_active=='index'}" @click="goNav('/')">首页</li>
                <li :class="{'active':nav_active=='about'}" @click="goNav('about')">关于九州</li>
                <li :class="{'active':nav_active=='join'}" @click="goNav('join')">招商加盟</li>
                <li :class="{'active':nav_active=='problem'}" @click="goNav('problem')">常见问题</li>
                <li :class="{'active':nav_active=='us'}" @click="goNav('us')">加入我们</li>
                <li :class="{'active':nav_active=='contact'}" @click="goNav('contact')">联系方式</li>
            </ul>
            <span v-if="screenWidth>1440"></span>
            <div class="phone-wrap" v-if="screenWidth>1440">
                <img src="@/assets/phone-icon-active.png" alt="" v-if="header_fixed">
                <img src="@/assets/phone-icon.png" alt="" v-else>
                <div>
                    客服电话
                    <span>400-0431639</span>
                </div>
            </div>

            <div class="nav-btn" v-if="screenWidth<=1440" @click="navClick">
                <img src="../assets/nav-btn.png" alt="">

            </div>

            <transition name="nav">
                <div class="nav-mobile" v-if="screenWidth<=1440 && !nav_btn">
                    <div class="nav-head">
                        <img src="@/assets/logo.png" alt="" class="logo">
                        <img src="../assets/nav-btn-close.png" alt="" @click="navClick" class="close-btn">
                    </div>
                    <ul>
                        <li :class="{'active':nav_active=='index'}" @click="goNav('/')">
                            <p>首页</p>
                        </li>
                        <li :class="{'active':nav_active=='about'}" @click="goNav('/about')">
                            <p>关于九州</p>
                            <p @click.stop="navItemClick(1)">{{nav_item_index == 1?'—':'+'}}</p>
                        </li>

                        <li class="item-children" v-if="nav_item_index==1">
                            <p>· 企业简介</p>
                            <p>· 发展历程</p>
                            <p>· 团队介绍</p>
                            <p>· 合作伙伴</p>
                        </li>
                        <li :class="{'active':nav_active=='join'}" @click="goNav('join')">
                            <p>招商加盟</p>
                            <p @click.stop="navItemClick(2)">{{nav_item_index == 1?'—':'+'}}</p>
                        </li>
                        <li class="item-children" v-if="nav_item_index==2">
                            <p>· 加盟背景</p>
                            <p>· 加盟优势</p>
                            <p>· 加盟费用</p>
                            <p>· 加盟条件</p>
                            <p>· 合作流程</p>
                        </li>
                        <li :class="{'active':nav_active=='problem'}" @click="goNav('problem')">
                            <p>常见问题</p>
                            <p @click.stop="navItemClick(3)">{{nav_item_index == 1?'—':'+'}}</p>
                        </li>
                        <li class="item-children" v-if="nav_item_index==3">
                            <p>· 软件问题</p>
                        </li>
                        <li :class="{'active':nav_active=='us'}" @click="goNav('us')">
                            <p>加入我们</p>
                            <p @click.stop="navItemClick(4)">{{nav_item_index == 1?'—':'+'}}</p>
                        </li>
                        <li class="item-children" v-if="nav_item_index==4">
                            <p>· 福利待遇</p>
                            <p>· 招聘岗位</p>
                        </li>
                        <li :class="{'active':nav_active=='contact'}" @click="goNav('contact')">
                            <p>联系方式</p>
                        </li>

                    </ul>
                </div>
            </transition>


        </div>
    </div>
</template>

<script>
    let _this;
    export default {
        name: "headerC",
        props: {
            header_fixed: Boolean,
            nav_active: '',
        },
        watch: {
            header_fixed() {
                console.log(this.header_fixed)
            }
        },
        created() {
            _this = this;
            console.log(_this.nav_active)
        },
        mounted() {
            window.addEventListener('resize', function () {
                window.screenWidth = document.body.clientWidth
                _this.screenWidth = window.screenWidth
                console.log(_this.screenWidth)

            })

        },
        data() {
            return {
                screenWidth: document.body.clientWidth,     // 屏幕宽
                nav_btn: true,
                nav_item_index: '',
            }
        },
        methods: {
            goNav(e) {
                this.$router.push({path:e});
            },

            // 导航点击事件
            navItemClick(index) {
                if (_this.nav_item_index == index) {
                    _this.nav_item_index = ''
                } else {
                    _this.nav_item_index = index
                }
            },
            navClick() {
                _this.nav_btn = !_this.nav_btn;
            }
        }
    }
</script>

<style lang="scss" scoped>


    .fixed {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        background-color: transparent;
    }

    .header-wrap.active {
        width: 100%;
        padding: 30px 0;
        box-sizing: border-box;
        background-color: #FFFFFF;

        .header {
            display: flex;
            width: 83.3333333%;
            max-width: 1600px;
            margin: 0 auto;
            align-items: center;

            .logo {
                width: 160px;
                height: 50px;
            }

            ul {
                padding-left: 15%;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                width: 58%;

                li {
                    padding: 10px 5px;
                    color: #333333;
                    box-sizing: border-box;
                    text-align: center;
                    font-size: 16px;
                    cursor: pointer;
                }

                .active {
                    border-bottom: 2px solid rgba(11, 111, 255, .4);
                    font-weight: bold;
                    color: #0B6FFF;
                }
            }

            & > span {
                display: block;
                background-color: #E6E6E6;
                margin: 0 3.5%;
                width: 1px;
                height: 24px;
            }

            .phone-wrap {
                display: flex;
                align-items: center;

                & > img {
                    width: 26px;
                    height: 26px;
                    margin-right: 10px;
                }

                div {

                    font-size: 16px;
                    font-weight: 400;
                    color: #555555;
                    height: 20px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;

                    & > span {
                        display: block;
                        margin-left: 15px;
                        font-family: DIN;
                        font-size: 20px;
                        color: #0B6FFF;
                        font-weight: 500;
                        height: 20px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .header-wrap {
        width: 100%;
        padding: 30px 0;
        box-sizing: border-box;
        background-color: transparent;
        transition: .5s;

        .header {
            display: flex;
            width: 83.3333333%;
            max-width: 1600px;
            margin: 0 auto;
            align-items: center;

            .logo {
                width: 160px;
                height: 50px;
            }

            & > ul {
                padding-left: 15%;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                width: 58%;

                li {
                    padding: 10px 5px;
                    color: #DBEAFF;
                    box-sizing: border-box;
                    text-align: center;
                    font-size: 16px;
                    cursor: pointer;
                }

                .active {
                    border-bottom: 2px solid #FFFFFF;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }

            & > span {
                display: block;
                background-color: rgba(255, 255, 255, .3);
                margin: 0 3.5%;
                width: 1px;
                height: 24px;
            }

            .phone-wrap {
                display: flex;
                align-items: center;

                & > img {
                    width: 26px;
                    height: 26px;
                    margin-right: 10px;
                }

                div {

                    font-size: 16px;
                    font-weight: 400;
                    color: #DBEAFF;
                    height: 20px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;

                    & > span {
                        display: block;
                        margin-left: 15px;
                        font-family: DIN;
                        font-size: 20px;
                        color: #FFFFFF;
                        font-weight: 500;
                        height: 20px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .header-wrap.mobile {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
        padding: 30px 0;
        box-sizing: border-box;
        background-color: transparent;

        .header {
            display: flex;
            justify-content: space-between;
            height: 56px;
            width: 100%;
            margin: 0 auto;
            align-items: center;
            padding: 0 30px;
            box-sizing: border-box;
        }

        .nav-btn {
            width: 40px;
            height: 40px;

            img {
                width: 40px;
                height: 40px;
            }
        }

        .nav-mobile {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
            background-color: #0A1837;
            padding: 0 30px !important;
            box-sizing: border-box;
            overflow-y: auto;

            .nav-head {
                display: flex;
                justify-content: space-between;
                padding: 31px 0;
                align-items: center;
                height: 56px;
                /*box-sizing: border-box;*/
                .close-btn {
                    width: 40px;
                    height: 40px;
                }
            }

            & > ul {
                & > li {
                    height: 80px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    align-items: center;
                    border-bottom: 1px solid rgba(255, 255, 255, .15);

                    p {

                        font-size: 20px;
                        font-weight: 100;
                        color: #FFFFFF;
                    }

                }

                li.active {
                    p {
                        color: #0B6FFF;
                    }
                }

                li.item-children {
                    border: 0;
                    background-color: rgba(255, 255, 255, .15);
                    border-radius: 0px 0px 10px 10px;
                    display: block;
                    height: auto;
                    padding: 0 20px;
                    box-sizing: border-box;

                    p {
                        font-size: 18px;
                        font-weight: 500;
                        color: #FFFFFF;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
        }

        .nav-enter {
            opacity: 0;
        }

        .nav-leave {
            opacity: 1;
        }

        .nav-enter-active {
            transition: opacity .5s;
        }

        .nav-leave-active {
            opacity: 0;
            transition: opacity .5s;
        }

    }

</style>