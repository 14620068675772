<template>
    <div class="home">

        <!--   头部导航     -->
        <headerC :header_fixed="header_fixed" :nav_active="nav_active"></headerC>

        <!--    轮播图    -->
        <el-carousel trigger="click" height="150px" arrow="never" :indicator-position="screenWidth<=1400?'outside':''"
                     :class="{'mobile-carousel':screenWidth<=1400}">
            <!--    移动端banner       -->
            <el-carousel-item v-if="screenWidth<=1400" class="mobile">
                <div class="banner-text">
                    <img src="../assets/banner-1-test-phone.png" alt="">

                </div>
            </el-carousel-item>

            <el-carousel-item v-if="screenWidth<=1400" class="mobile">

            </el-carousel-item>

            <el-carousel-item v-if="screenWidth<=1400" class="mobile">

            </el-carousel-item>


            <!--    pc端banner        -->
            <el-carousel-item v-if="screenWidth>1400" class="pc">
                <div class="banner-text">
                    <img src="../assets/banner-1-test.png" alt="">
                    <div @click="goNav('/about')">
                        Read more
                        <img src="../assets/read-more-icon.png" alt="">
                    </div>
                </div>
            </el-carousel-item>

            <el-carousel-item v-if="screenWidth>1400" class="pc">

            </el-carousel-item>

            <el-carousel-item v-if="screenWidth>1400" class="pc">
                <div class="banner-text">
                    <div @click="goNav('/us')">
                        Read more
                        <img src="../assets/read-more-icon.png" alt="">
                    </div>
                </div>
            </el-carousel-item>

        </el-carousel>

        <!--    介绍pc端    -->
        <div class="introduce-max" v-if="screenWidth>1400">
            <div class="introduce">
                <div>
                    <img src="../assets/introduce-1.png" alt="">
                    <h4>划圈计费</h4>
                    <p>代驾十公里39元起</p>
                    <p>计费更省心 系统透明化</p>
                </div>

                <div>
                    <img src="../assets/introduce-2.png" alt="">
                    <h4>AI派单</h4>
                    <p>AI智能派单模式</p>
                    <p>一分钟 响应服务请求</p>
                </div>

                <div>
                    <img src="../assets/introduce-3.png" alt="">
                    <h4>全天售后</h4>
                    <p>提供24小时售后服务</p>
                    <p>一键呼叫我帮您</p>
                </div>

                <div>
                    <img src="../assets/introduce-4.png" alt="">
                    <h4>省心安全</h4>
                    <p>认证司机，五年以上驾龄</p>
                    <p>保证安全至上</p>
                </div>

                <div>
                    <img src="../assets/introduce-5.png" alt="">
                    <h4>免费广告</h4>
                    <p>合作商家免费提供</p>
                    <p>app广告宣传</p>
                </div>
            </div>
        </div>

        <!--    介绍移动端    -->
        <vue-scroll :ops="ops_mobile" v-if="screenWidth<=1400">

            <div class="introduce-max">
                <div class="introduce">
                    <div>
                        <img src="../assets/introduce-1.png" alt="">
                        <h4>划圈计费</h4>
                        <p>代驾十公里39元起</p>
                        <p>计费更省心 系统透明化</p>
                    </div>

                    <div>
                        <img src="../assets/introduce-2.png" alt="">
                        <h4>AI派单</h4>
                        <p>AI智能派单模式</p>
                        <p>一分钟 响应服务请求</p>
                    </div>

                    <div>
                        <img src="../assets/introduce-3.png" alt="">
                        <h4>全天售后</h4>
                        <p>提供24小时售后服务</p>
                        <p>一键呼叫我帮您</p>
                    </div>

                    <div>
                        <img src="../assets/introduce-4.png" alt="">
                        <h4>省心安全</h4>
                        <p>认证司机，五年以上驾龄</p>
                        <p>保证安全至上</p>
                    </div>

                    <div>
                        <img src="../assets/introduce-5.png" alt="">
                        <h4>免费广告</h4>
                        <p>合作商家免费提供</p>
                        <p>app广告宣传</p>
                    </div>
                </div>
            </div>


        </vue-scroll>

        <!--   软件介绍pc端     -->
        <div class="software-max" v-if="screenWidth>1400">
            <div class="software">
                <div class="title">
                    <h3>软件介绍</h3>
                    <p>Software introduction</p>
                </div>

                <div class="main">

                    <!--    选择司机端显示内容     -->
                    <div class="left" v-show="software_show == 'driver'">
                        <div class="software-left">
                            <h3>九州代驾司机端 <span>APP</span></h3>
                            <p>AI派单，快速接单</p>
                            <p>全职/专职司机，灵活就业</p>
                            <div class="QR-code">
                                <div style="margin-right: 30px">
                                    <img src="../assets/ios-driver.png" alt="" class="top">
                                    <img src="../assets/ios-icon.png" alt="">
                                    <p>IOS 苹果端</p>
                                </div>
                                <div>
                                    <img src="../assets/android-driver.png" alt="" class="top">
                                    <img src="../assets/android-icon.png" alt="">
                                    <p>Android 安卓端</p>
                                </div>
                            </div>
                        </div>
                        <div class="software-right">
                            <div class="software-right-top">
                                <img src="../assets/software-mode.png" alt="">
                                一键勾选，开启听单模式
                            </div>
                            <img src="../assets/software-phone.png" alt="">
                            <div class="software-right-bottom">
                                <img src="../assets/software-money.png" alt="">
                                收入提成，轻松掌握
                            </div>
                        </div>
                    </div>


                    <!--    选择乘客端显示内容     -->
                    <div class="left" v-show="software_show == 'consumer'">
                        <div class="software-left">
                            <h3>九州代驾乘客端<span>APP</span></h3>
                            <p>一键呼叫代驾司机，最快10分钟到达</p>
                            <p>即将上线全新的九州跑腿功能</p>
                            <div class="QR-code">
                                <div style="margin-right: 30px">
                                    <img src="../assets/ios-consumer.png" alt="" class="top">
                                    <img src="../assets/ios-icon.png" alt="">
                                    <p>IOS 苹果端</p>
                                </div>
                                <div>
                                    <img src="../assets/android-consumer.png" alt="" class="top">
                                    <img src="../assets/android-icon.png" alt="">
                                    <p>Android 安卓端</p>
                                </div>
                            </div>
                        </div>
                        <div class="software-right">
                            <div class="software-right-top">
                                <img src="../assets/consumer-top.png" alt="">
                                不同需求，调整模式下单
                            </div>
                            <img src="../assets/consumer-phone.png" alt="">
                            <div class="software-right-bottom">
                                <img src="../assets/consumer-bottom.png" alt="">
                                一键下单，司机快速接单
                            </div>
                        </div>
                    </div>


                    <!--    选择小程序显示内容     -->
                    <div class="left" v-show="software_show == 'chat'">
                        <div class="software-left">
                            <h3>九州代驾乘客端<span>小程序</span></h3>
                            <p>微信和支付宝小程序，不占内存</p>
                            <p>使用更加方便灵活</p>
                            <div class="QR-code">
                                <div style="margin-right: 30px">
                                    <img src="../assets/weixin.png" alt="" class="top">
                                    <img src="../assets/weixin-icon.png" alt="">
                                    <p>IOS 苹果端</p>
                                </div>
                                <div>
                                    <img src="../assets/zhifubao.png" alt="" class="top">
                                    <img src="../assets/zhifubao-icon.png" alt="">
                                    <p>Android 安卓端</p>
                                </div>
                            </div>
                        </div>
                        <div class="software-right">
                            <div class="software-right-top">
                                <img src="../assets/chat-top.png" alt="">
                                灵活使用，方便快捷
                            </div>
                            <img src="../assets/chat-phone.png" alt="">
                            <div class="software-right-bottom">
                                <img src="../assets/consumer-bottom.png" alt="">
                                一键下单，司机快速接单
                            </div>
                        </div>
                    </div>


                    <!--    右侧tab选项       -->
                    <div class="right">
                        <div :class="{'active':software_show == 'consumer'}" @click="softwareChange('consumer')">
                            <img src="../assets/software-menu-active.png" alt="" class="software-menu"
                                 v-if="software_show == 'consumer'">
                            <img src="../assets/software-menu.png" alt="" class="software-menu" v-else>
                            <p>乘客端APP</p>
                        </div>
                        <span></span>
                        <div :class="{'active':software_show == 'driver'}" @click="softwareChange('driver')">
                            <img src="../assets/software-menu-active.png" alt="" class="software-menu"
                                 v-if="software_show == 'driver'">
                            <img src="../assets/software-menu.png" alt="" class="software-menu" v-else>
                            <p>司机端APP</p>
                        </div>
                        <span></span>
                        <div :class="{'active':software_show == 'chat'}" @click="softwareChange('chat')">
                            <img src="../assets/software-menu-active.png" alt="" class="software-menu"
                                 v-if="software_show == 'chat'">
                            <img src="../assets/software-menu.png" alt="" class="software-menu" v-else>
                            <p>小程序</p>
                        </div>
                    </div>
                </div>

            </div>


        </div>

        <!--   软件介绍移动端     -->
        <div class="software-max mobile" v-if="screenWidth<=1400">
            <div class="software">
                <div class="title">
                    <h3>软件介绍</h3>
                    <p>Software introduction</p>
                </div>

                <div class="main">

                    <!--    选择司机端显示内容     -->
                    <div class="left" v-show="software_show == 'driver'">
                        <div class="software-left">
                            <h3>九州代驾司机端 APP</h3>
                            <p>AI派单，快速接单</p>
                            <p>全职/专职司机，灵活就业</p>
                            <div class="QR-code">

                                <div class="box" @click="QRCodeClick('iosDriver')">
                                    <img src="../assets/ios-icon.png" alt="">
                                    <p>IOS 苹果端</p>
                                </div>
                                <div class="box" @click="QRCodeClick('androidDriver')">
                                    <img src="../assets/android-icon.png" alt="">
                                    <p>Android 安卓端</p>
                                </div>


                                <div class="code-details" v-if="QRCode_show == 'iosDriver'" @click="QRCodeClose">
                                    <img src="../assets/ios-driver.png" alt="" class="top">
                                    <img src="../assets/ios-icon.png" alt="">
                                    <p>IOS 苹果端</p>
                                </div>

                                <div class="code-details" v-if="QRCode_show == 'androidDriver'" @click="QRCodeClose">
                                    <img src="../assets/android-driver.png" alt="" class="top">
                                    <img src="../assets/android-icon.png" alt="">
                                    <p>Android 安卓端</p>
                                </div>
                            </div>
                        </div>
                        <div class="software-right">

                            <img src="../assets/software-phone.png" alt="">

                        </div>
                    </div>


                    <!--    选择乘客端显示内容     -->
                    <div class="left" v-show="software_show == 'consumer'">
                        <div class="software-left">
                            <h3>九州代驾乘客端<span>APP</span></h3>
                            <p>一键呼叫代驾司机，最快10分钟到达</p>
                            <p>即将上线全新的九州跑腿功能</p>
                            <div class="QR-code">

                                <div class="box" @click="QRCodeClick('iosConsumer')">
                                    <img src="../assets/ios-icon.png" alt="">
                                    <p>IOS 苹果端</p>
                                </div>
                                <div class="box" @click="QRCodeClick('androidConsumer')">
                                    <img src="../assets/android-icon.png" alt="">
                                    <p>Android 安卓端</p>
                                </div>


                                <div class="code-details" v-if="QRCode_show == 'iosConsumer'" @click="QRCodeClose">
                                    <img src="../assets/ios-consumer.png" alt="" class="top">
                                    <img src="../assets/ios-icon.png" alt="">
                                    <p>IOS 苹果端</p>
                                </div>
                                <div class="code-details" v-if="QRCode_show == 'androidConsumer'" @click="QRCodeClose">
                                    <img src="../assets/android-consumer.png" alt="" class="top">
                                    <img src="../assets/android-icon.png" alt="">
                                    <p>Android 安卓端</p>
                                </div>


                            </div>
                        </div>
                        <div class="software-right">

                            <img src="../assets/consumer-phone.png" alt="">

                        </div>
                    </div>


                    <!--    选择小程序显示内容     -->
                    <div class="left" v-show="software_show == 'chat'">
                        <div class="software-left">
                            <h3>九州代驾乘客端<span>小程序</span></h3>
                            <p>微信和支付宝小程序，不占内存</p>
                            <p>使用更加方便灵活</p>
                            <div class="QR-code">


                                <div class="box" @click="QRCodeClick('weixin')">
                                    <img src="../assets/weixin-icon.png" alt="">
                                    <p>微信小程序</p>
                                </div>
                                <div class="box" @click="QRCodeClick('zhifubao')">
                                    <img src="../assets/zhifubao-icon.png" alt="">
                                    <p>支付宝小程序</p>
                                </div>


                                <div class="code-details" v-if="QRCode_show == 'weixin'" @click="QRCodeClose">
                                    <img src="../assets/weixin.png" alt="" class="top">
                                    <img src="../assets/weixin-icon.png" alt="">
                                    <p>微信小程序</p>
                                </div>
                                <div class="code-details" v-if="QRCode_show == 'zhifubao'" @click="QRCodeClose">
                                    <img src="../assets/zhifubao.png" alt="" class="top">
                                    <img src="../assets/zhifubao-icon.png" alt="">
                                    <p>支付宝小程序</p>
                                </div>
                            </div>
                        </div>
                        <div class="software-right">

                            <img src="../assets/chat-phone.png" alt="">

                        </div>
                    </div>


                    <!--    右侧tab选项       -->
                    <div class="right">
                        <div :class="{'active':software_show == 'consumer'}" @click="softwareChange('consumer')">
                            <img src="../assets/software-menu-active.png" alt="" class="software-menu"
                                 v-if="software_show == 'consumer'">
                            <img src="../assets/software-menu.png" alt="" class="software-menu" v-else>

                        </div>

                        <div :class="{'active':software_show == 'driver'}" @click="softwareChange('driver')">
                            <img src="../assets/software-menu-active.png" alt="" class="software-menu"
                                 v-if="software_show == 'driver'">
                            <img src="../assets/software-menu.png" alt="" class="software-menu" v-else>

                        </div>

                        <div :class="{'active':software_show == 'chat'}" @click="softwareChange('chat')">
                            <img src="../assets/software-menu-active.png" alt="" class="software-menu"
                                 v-if="software_show == 'chat'">
                            <img src="../assets/software-menu.png" alt="" class="software-menu" v-else>
                        </div>
                    </div>
                </div>

            </div>


        </div>


        <!--      适用场景pc端          -->
        <div class="scene-max" v-if="screenWidth>1400">
            <div class="scene">
                <div class="title">
                    <h3>适用场景</h3>
                    <p>Applicable scenarios</p>
                </div>

                <ul class="scene-wrap">
                    <li class="scene-item">
                        <img src="../assets/scene-1.png" alt="">
                        <div>
                            <h4>酒后代驾</h4>
                            <p>周末和朋友小聚，把酒言欢自然少不了，车子放在那 里又不放心，呼叫九州代驾解决您的问题</p>
                        </div>
                    </li>
                    <li class="scene-item">
                        <img src="../assets/scene-2.png" alt="">
                        <div>
                            <h4>商务代驾</h4>
                            <p>去机场接客户，车上有几个问题要讨论，又怕开车分心 呼叫九州代驾替您开车，轻松解决</p>
                        </div>
                    </li>
                    <li class="scene-item">
                        <img src="../assets/scene-3.png" alt="">
                        <div>
                            <h4>跑腿代购</h4>
                            <p>文件，食物，鲜花，想快速送到对方手里，可是暂时无 法前往，一键呼叫九州，帮您送达</p>
                        </div>
                    </li>
                </ul>

            </div>
        </div>


        <!--      适用场景移动端          -->
        <div class="scene-max mobile" v-if="screenWidth<=1400">
            <div class="scene">
                <div class="title">
                    <h3>适用场景</h3>
                    <p>Applicable scenarios</p>
                </div>

                <vue-scroll :ops="ops_mobile">
                    <ul class="scene-wrap">
                        <li class="scene-item">
                            <img src="../assets/scene-1.png" alt="">
                            <div>
                                <h4>酒后代驾</h4>
                                <p>周末和朋友小聚，把酒言欢自然少不了，车子放在那 里又不放心，呼叫九州代驾解决您的问题</p>
                            </div>
                        </li>
                        <li class="scene-item">
                            <img src="../assets/scene-2.png" alt="">
                            <div>
                                <h4>商务代驾</h4>
                                <p>去机场接客户，车上有几个问题要讨论，又怕开车分心 呼叫九州代驾替您开车，轻松解决</p>
                            </div>
                        </li>
                        <li class="scene-item">
                            <img src="../assets/scene-3.png" alt="">
                            <div>
                                <h4>跑腿代购</h4>
                                <p>文件，食物，鲜花，想快速送到对方手里，可是暂时无 法前往，一键呼叫九州，帮您送达</p>
                            </div>
                        </li>
                    </ul>
                </vue-scroll>
            </div>
        </div>


        <!--   常见问题     -->
        <div class="problem-max" :class="{'mobile':screenWidth<=1400}">
            <div class="problem">
                <div class="title">
                    <h3>常见问题</h3>
                    <p>Common problem</p>
                </div>

                <div class="problem-main">
                    <div class="main-item" @mouseenter="problemHover(0)" @mouseleave="problemLeave">
                        <div>
                            <img src="../assets/problem-icon-active.png" alt="" v-if="problem_hover === 0">
                            <img src="../assets/problem-icon.png" alt="" v-else>
                            <p>九州代驾手机客户端都支持哪些手机系统？</p>
                        </div>
                        <img src="../assets/problem-right.png" alt="">
                    </div>
                    <div class="main-item" @mouseenter="problemHover(1)" @mouseleave="problemLeave">
                        <div>
                            <img src="../assets/problem-icon-active.png" alt="" v-if="problem_hover === 1">
                            <img src="../assets/problem-icon.png" alt="" v-else>
                            <p>代驾师傅多久能到达客户指定地点？</p>
                        </div>
                        <img src="../assets/problem-right.png" alt="">
                    </div>
                    <div class="main-item" @mouseenter="problemHover(2)" @mouseleave="problemLeave">
                        <div>
                            <img src="../assets/problem-icon-active.png" alt="" v-if="problem_hover === 2">
                            <img src="../assets/problem-icon.png" alt="" v-else>
                            <p>九州代驾如何收费？</p>
                        </div>
                        <img src="../assets/problem-right.png" alt="">
                    </div>
                    <div class="main-item" @mouseenter="problemHover(3)" @mouseleave="problemLeave">
                        <div>
                            <img src="../assets/problem-icon-active.png" alt="" v-if="problem_hover === 3">
                            <img src="../assets/problem-icon.png" alt="" v-else>
                            <p>客户取消代驾，是否可以收费用？</p>
                        </div>
                        <img src="../assets/problem-right.png" alt="">
                    </div>
                    <div class="main-item" v-if="screenWidth>1400" @mouseenter="problemHover(4)"
                         @mouseleave="problemLeave">
                        <div>
                            <img src="../assets/problem-icon-active.png" alt="" v-if="problem_hover === 4">
                            <img src="../assets/problem-icon.png" alt="" v-else>
                            <p>哪些情况不属于九州代驾服务范畴？</p>
                        </div>
                        <img src="../assets/problem-right.png" alt="">
                    </div>
                    <div class="main-item" v-if="screenWidth>1400" @mouseenter="problemHover(5)"
                         @mouseleave="problemLeave">
                        <div>
                            <img src="../assets/problem-icon-active.png" alt="" v-if="problem_hover === 5">
                            <img src="../assets/problem-icon.png" alt="" v-else>
                            <p>司机在何种条件下可索要打车费用？</p>
                        </div>
                        <img src="../assets/problem-right.png" alt="">
                    </div>
                    <div class="main-item" v-if="screenWidth>1400" @mouseenter="problemHover(6)"
                         @mouseleave="problemLeave">
                        <div>
                            <img src="../assets/problem-icon-active.png" alt="" v-if="problem_hover === 6">
                            <img src="../assets/problem-icon.png" alt="" v-else>
                            <p>代驾司机在为其代驾过程中出现交通违规该如何处理？</p>
                        </div>
                        <img src="../assets/problem-right.png" alt="">
                    </div>
                    <div class="main-item" v-if="screenWidth>1400" @mouseenter="problemHover(7)"
                         @mouseleave="problemLeave">
                        <div>
                            <img src="../assets/problem-icon-active.png" alt="" v-if="problem_hover === 7">
                            <img src="../assets/problem-icon.png" alt="" v-else>
                            <p>发票如何开具？</p>
                        </div>
                        <img src="../assets/problem-right.png" alt="">
                    </div>
                </div>

            </div>
        </div>

        <!--   开通城市     -->
        <div class="city-max" :class="{'mobile':screenWidth<=1400}">
            <div class="city">
                <div class="title">
                    <h3>九州开通城市</h3>
                    <p>Open the city</p>
                </div>

                <div class="city-main">
                    <div>
                        <vue-scroll :ops="ops">
                            <div class="left">
                                <div class="city-item" v-for="(item,index) in open_city">
                                    <div class="title">{{item.title}}</div>
                                    <ul>
                                        <li v-for="(item2,index2) in item.children">{{item2}}</li>
                                    </ul>
                                </div>
                            </div>
                        </vue-scroll>
                    </div>

                    <img src="../assets/city-map.png" alt="">
                </div>
            </div>
        </div>


        <footerC></footerC>

    </div>
</template>

<script>
    let _this;
    import headerC from "@/components/headerC.vue";
    import footerC from "@/components/footerC.vue";

    export default {

        name: "Home",
        components: {
            headerC,
            footerC
        },
        data() {
            return {
                software_show: 'driver',
                problem_hover: '',

                screenWidth: document.body.clientWidth,     // 屏幕宽

                // 显示二维码
                QRCode_show: '',

                open_city: [
                    {
                        title: '黑龙江',
                        children: ['哈尔滨']
                    },
                    {
                        title: '河北',
                        children: ['石家庄', '栾城区', '邯郸市', '保定',
                            '唐山', '正定', '威县', '邢台', '秦皇岛', '涿州', '安平县', '青县', '定州市', '晋州市',
                            '辛集市', '元氏县']
                    }
                    ,
                    {
                        title: '山东',
                        children: ['济南', '菏泽', '淄博', '德州', '青岛']
                    },
                    {
                        title: '山西',
                        children: ['太原']
                    }
                    ,
                    {
                        title: '江苏',
                        children: ['南京']
                    }
                    ,
                    {
                        title: '陕西',
                        children: ['西安']
                    }
                    ,
                    {
                        title: '河南',
                        children: ['郑州']
                    }
                    ,
                    {
                        title: '吉林',
                        children: ['农安县']
                    }
                    ,
                    {
                        title: '内蒙古',
                        children: ['赤峰']
                    },
                    {
                        title: '云南',
                        children: ['昆明', '红河']
                    }
                    ,
                    {
                        title: '天津',
                        children: ['天津']
                    }
                ],

                scroll: '',
                header_fixed: false,
                nav_active: 'index',

                //    自定义滚动条样式
                ops_mobile: {
                    vuescroll: {},
                    scrollPanel: {},
                    rail: {
                        keepShow: false,
                        specifyBorderRadius: '0',
                    },
                    bar: {
                        specifyBorderRadius: '0',
                        keepShow: false,
                        onlyShowBarOnScroll: false,
                        hoverStyle: false,
                        onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
                        background: "",//滚动条颜色
                        opacity: 1,//滚动条透明度
                        "overflow-x": "hidden"
                    }
                },

                //    自定义滚动条样式
                ops: {
                    vuescroll: {},
                    scrollPanel: {},
                    rail: {
                        keepShow: true,
                        specifyBorderRadius: '0',
                    },
                    bar: {
                        specifyBorderRadius: '0',
                        keepShow: true,
                        onlyShowBarOnScroll: false,
                        hoverStyle: true,
                        onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
                        background: "#ffffff",//滚动条颜色
                        opacity: 1,//滚动条透明度
                        "overflow-x": "hidden"
                    }
                }
            }
        },
        mounted() {
            // 监听页面滚动事件
            window.addEventListener('scroll', this.menu)


            // 监听窗口宽度变化
            window.addEventListener('resize', function () {
                window.screenWidth = document.body.clientWidth
                _this.screenWidth = window.screenWidth
                console.log(_this)
            })
        },
        watch: {
            scroll() {
                if (_this.scroll > 0) {
                    _this.header_fixed = true
                } else {
                    _this.header_fixed = false
                }
            }
        },
        created() {
            // console.log(_this)
            _this = this;
        },
        methods: {
            goNav(e) {
                this.$router.push({path: e});
            },
            QRCodeClose() {
                _this.QRCode_show = ''
            },
            QRCodeClick(val) {
                _this.QRCode_show = val
            },
            menu() {
                this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
                // console.log(this.scroll)

            },
            // 软件介绍点击事件
            softwareChange(val) {
                _this.software_show = val
            },
            problemHover(val) {
                _this.problem_hover = val
            },
            problemLeave() {
                _this.problem_hover = ''
            }
        }
    }
    ;
</script>
<style lang="scss" scoped>
    .el-carousel__item.pc:nth-of-type(1) {
        .banner-text {
            position: absolute;
            top: 50%;
            left: 13%;
            transform: translateY(-100%);
            width: 100%;

            & > img {
                width: 25%;
            }

            div {
                width: 10%;
                height: 60px;
                line-height: 60px;
                font-size: 16px;
                font-weight: 400;
                margin-top: 80px;
                color: #FFFFFF;
                background-color: rgba(0, 0, 0, .2);
                text-align: center;
                border-radius: 30px;
                cursor: pointer;
                transition: .5s;
            }

            div:hover {
                background-color: rgba(0, 0, 0, 1);
            }
        }
    }

    .el-carousel__item.pc:nth-of-type(3) {
        .banner-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
            width: 100%;
            text-align: center;

            & > img {
                width: 25%;
            }

            div {
                width: 10%;
                height: 60px;
                line-height: 60px;
                font-size: 16px;
                font-weight: 400;
                margin: 80px auto 0;
                color: #FFFFFF;
                background-color: rgba(0, 0, 0, .2);
                text-align: center;
                border-radius: 30px;
                cursor: pointer;
                transition: .5s;

            }

            div:hover {
                background-color: rgba(0, 0, 0, 1);
            }
        }
    }

    .el-carousel__item.mobile {
        .banner-text {
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;

            & > img {
                width: 60%;
            }

            div {
                width: 10%;
                height: 870px;
                line-height: 60px;
                font-size: 16px;
                font-weight: 400;
                margin-top: 80px;
                color: #FFFFFF;
                background-color: rgba(0, 0, 0, .2);
                text-align: center;
                border-radius: 30px;
                cursor: pointer;
                transition: .5s;
            }

            div:hover {
                background-color: rgba(0, 0, 0, 1);
            }
        }
    }


    .el-carousel__item.mobile:nth-of-type(1) {
        background: url("../assets/banner-1-phone.png") no-repeat;
        background-size: 100%;
    }

    .el-carousel__item.mobile:nth-of-type(2) {
        background: url("../assets/banner-2-phone.png") no-repeat;
        background-size: 100%;
    }

    .el-carousel__item.mobile:nth-of-type(3) {
        background: url("../assets/banner-3-phone.png") no-repeat;
        background-size: 100%;
    }

    .el-carousel__item.pc:nth-of-type(1) {
        background: url("../assets/banner.png") no-repeat;
        background-size: 100%;
    }

    .el-carousel__item.pc:nth-of-type(2) {
        background: url("../assets/banner-2.png") no-repeat;
        background-size: 100%;
    }

    .el-carousel__item.pc:nth-of-type(3) {
        background: url("../assets/banner-3.png") no-repeat;
        background-size: 100%;
    }

    .introduce-max {
        width: 100%;
        margin-top: 120px;

        .introduce {
            max-width: 1600px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            & > div {
                margin: 0 30px;
                width: 150px;
            }
        }

        & > div {


            img {
                display: block;
                width: 60px;
                margin: 0 auto 24px;
            }

            h4 {
                text-align: center;
                margin-bottom: 14px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
            }

            p {
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    .software-max {
        width: 100%;
        background-image: url("../assets/software-bg.png");
        background-size: 100%;
        background-repeat: no-repeat;
        height: 1158px;

        .software {
            max-width: 1600px;
            margin: 100px auto 0;

            .title {
                h3 {
                    text-align: center;
                    font-size: 42px;
                    font-weight: 400;
                    color: #333333;
                }

                p {
                    text-align: center;
                    font-family: Microsoft YaHei;
                    letter-spacing: 5px;
                    margin-top: 20px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #888888;
                }
            }

            .main {
                display: flex;

                .left {
                    width: 85%;
                    display: flex;

                    .software-left {
                        width: 30%;

                        h3 {
                            padding-top: 100px;
                            box-sizing: border-box;
                            font-size: 30px;
                            color: #333333;
                            font-weight: bold;
                            margin-bottom: 40px;

                            span {
                                display: block;
                            }
                        }

                        p {

                            font-size: 18px;
                            color: #666666;
                            font-weight: 400;
                        }

                        .QR-code {
                            margin-top: 60px;
                            display: flex;

                            div {

                                background: #FFFFFF;
                                border-radius: 6px;
                                width: 182px;
                                height: 316px;
                                box-shadow: 1px 0px 10px #c1ddff;
                                padding: 10px;
                                box-sizing: border-box;

                                img {
                                    display: block;
                                    margin: 0 auto;
                                }

                                .top {
                                    width: 100%;
                                }

                                img:nth-of-type(1) {
                                    margin-bottom: 30px;
                                }

                                img:nth-of-type(2) {
                                    margin-bottom: 16px;
                                }

                                p {

                                    font-size: 18px;
                                    color: #333333;
                                    font-weight: bold;
                                    text-align: center;
                                }
                            }

                        }
                    }

                    .software-right {
                        margin-left: 10%;
                        position: relative;
                        padding-top: 40px;
                        box-sizing: border-box;

                        .software-right-top {
                            position: absolute;
                            top: 20%;
                            left: -10%;
                            display: flex;
                            align-items: center;
                            font-size: 18px;
                            color: #333333;
                            font-weight: 400;
                        }

                        & > img {
                            width: 100%;
                        }

                        .software-right-bottom {
                            position: absolute;
                            bottom: 5%;
                            right: 5%;
                            display: flex;
                            align-items: center;
                            font-size: 18px;
                            color: #333333;
                            font-weight: 400;
                        }
                    }


                }

                .right {
                    width: 15%;
                    padding-top: 145px;
                    box-sizing: border-box;

                    div {
                        display: flex;
                        cursor: pointer;
                        align-items: center;

                        .software-menu {
                            display: block;
                            margin-right: 20px;
                            width: 19px;
                            height: 19px;
                        }

                        .software-menu.active {
                            width: 23px;
                            height: 23px;
                        }

                        p {

                            font-size: 24px;
                            font-weight: 400;
                            color: #333333;
                        }
                    }

                    .active {
                        p {
                            color: #0B6FFF;
                        }

                        .software-menu {
                            width: 23px;
                            height: 23px;
                        }
                    }

                    span {
                        display: block;
                        width: 1px;
                        height: 120px;
                        background: #C6DDFD;
                        transform: translateX(10px);
                        margin: 20px 0;
                    }
                }
            }
        }
    }

    .software-max.mobile {
        background-position: bottom;
        height: auto;
        padding-bottom: 50px;
        box-sizing: border-box;

        .title {
            h3 {
                text-align: center;
                font-size: 24px;
                font-weight: 400;
                color: #333333;
            }

            p {
                text-align: center;
                font-family: Microsoft YaHei;
                letter-spacing: 2px;
                margin-top: 10px;
                font-size: 12px;
                font-weight: 400;
                color: #888888;
            }
        }

        .main {
            display: block;

            .left {
                width: 100%;
                display: block;

                .software-left {
                    width: 100%;

                    h3 {
                        font-size: 20px;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 20px;
                    }

                    p {
                        text-align: center;
                        font-size: 14px;
                    }

                    .QR-code {
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-around;

                        .box {
                            box-shadow: none;
                            height: auto;

                            p {
                                font-size: 14px;

                            }
                        }

                        .code-details {
                            position: fixed;
                            top: 50%;
                            left: 50%;
                            z-index: 9999;
                            transform: translate(-50%, -50%);
                        }
                    }
                }


            }

            .right {
                display: flex;
                width: 100%;
                justify-content: center;
                padding-top: 50px;

                div {
                    display: flex;
                    justify-content: center;

                    img {
                        margin: 0 10px !important;
                    }
                }
            }
        }
    }

    .scene-max.mobile {
        background-position: bottom;
        background-image: url("../assets/scene-bg-phone.png");

        .scene {
            margin: 0 auto;
            transform: translateY(0);

            .title {
                h3 {
                    text-align: center;
                    font-size: 24px;
                    font-weight: 400;
                    color: #333333;
                }

                p {
                    text-align: center;
                    font-family: Microsoft YaHei;
                    letter-spacing: 3px;
                    margin-top: 20px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #888888;
                }
            }

            .scene-wrap {
                .scene-item {
                    width: 250px;
                    margin: 0 15px;

                    div {
                        padding: 15px 25px;

                        h4 {
                            font-size: 16px;
                            margin-bottom: 15px;
                        }

                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .scene-max {
        width: 100%;
        background-image: url("../assets/scene-bg.png");
        background-size: 100%;
        background-repeat: no-repeat;
        height: 443px;

        .scene {
            max-width: 1600px;
            margin: 0 auto;
            transform: translateY(-250px);

            .title {
                h3 {
                    text-align: center;
                    font-size: 42px;
                    font-weight: 400;
                    color: #333333;
                }

                p {
                    text-align: center;
                    font-family: Microsoft YaHei;
                    letter-spacing: 5px;
                    margin-top: 20px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #888888;
                }
            }

            .scene-wrap {
                margin-top: 50px;
                display: flex;
                justify-content: space-between;

                .scene-item {
                    width: 31%;
                    border-radius: 20px;
                    background: #FFFFFF;
                    max-width: 500px;

                    img {
                        width: 100%;
                        display: block;
                    }

                    div {
                        padding: 20px 40px;
                        box-sizing: border-box;

                        h4 {
                            font-size: 24px;
                            font-weight: 400;
                            color: #333333;
                            margin-bottom: 24px;
                        }

                        p {

                            font-size: 16px;
                            font-weight: 400;
                            color: #666666;
                        }
                    }
                }
            }
        }
    }

    .problem-max.mobile {
        .problem {
            .title {
                h3 {
                    text-align: center;
                    font-size: 24px;
                    font-weight: 400;
                    color: #333333;
                }

                p {
                    text-align: center;
                    font-family: Microsoft YaHei;
                    letter-spacing: 3px;
                    margin-top: 20px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #888888;
                }
            }

            .problem-main {
                padding: 0 30px;
                box-sizing: border-box;

                .main-item {
                    width: 100%;

                    div {
                        p {

                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .problem-max {
        width: 100%;

        .problem {
            max-width: 1600px;
            margin: 118px auto 0;

            .title {
                h3 {
                    text-align: center;
                    font-size: 42px;
                    font-weight: 400;
                    color: #333333;
                }

                p {
                    text-align: center;
                    font-family: Microsoft YaHei;
                    letter-spacing: 5px;
                    margin-top: 20px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #888888;
                }

            }

            .problem-main {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .main-item {
                    width: 49%;
                    height: 70px;
                    margin: 15px 0;
                    background: #F8F8F8;
                    display: flex;
                    align-items: center;
                    padding: 0 30px;
                    box-sizing: border-box;
                    justify-content: space-between;
                    cursor: pointer;
                    transition: .5s;

                    &:hover {
                        background: #FF9600;

                        & > div > p {
                            color: #fff;
                        }
                    }

                    div {
                        display: flex;
                        align-items: center;

                        & > img {
                            width: 26px;
                            height: 26px;
                            display: block;
                            margin-right: 20px;
                        }

                        p {
                            font-size: 18px;
                            font-weight: 400;
                        }
                    }

                    & > img {
                        float: right;
                    }
                }
            }
        }
    }

    .city-max.mobile {
        .city {
            .title {
                h3 {
                    text-align: center;
                    font-size: 24px;
                    font-weight: 400;
                    color: #FFFFFF;
                }

                p {
                    text-align: center;
                    font-family: Microsoft YaHei;
                    letter-spacing: 5px;
                    margin-top: 20px;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(255, 255, 255, .6);
                }

            }

            .city-main {
                padding: 0 30px 60px;
                box-sizing: border-box;
                position: relative;

                & > div {
                    width: 100%;
                    height: 300px;
                }

                & > img {
                    position: absolute;
                    width: 80%;
                    top: 330px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .city-max {
        width: 100%;
        background-image: url("../assets/city-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-bottom: 120px;
        box-sizing: border-box;

        .city {
            max-width: 1600px;
            margin: 120px auto 0;
            padding-top: 120px;
            box-sizing: border-box;

            .title {
                h3 {
                    text-align: center;
                    font-size: 42px;
                    font-weight: 400;
                    color: #FFFFFF;
                }

                p {
                    text-align: center;
                    font-family: Microsoft YaHei;
                    letter-spacing: 5px;
                    margin-top: 20px;
                    font-size: 18px;
                    font-weight: 400;
                    color: rgba(255, 255, 255, .6);
                }

            }

            .city-main {
                display: flex;
                margin-top: 50px;
                position: relative;

                & > div {
                    width: 60%;
                    height: 367px;
                    border: 1px solid rgba(255, 255, 255, .45);

                    .left {
                        padding: 0 40px;
                        box-sizing: border-box;

                        .city-item {
                            height: 68px;
                            line-height: 68px;
                            border-bottom: 1px solid rgba(255, 255, 255, .3);
                            display: flex;

                            .title {

                                font-size: 18px;
                                color: #FFFFFF;
                                font-weight: bold;
                                width: 86px;
                            }

                            ul {
                                display: flex;

                                li {
                                    width: 48px;
                                    margin: 0 10px;
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                }

                & > img {
                    width: 50%;
                    max-width: 902px;
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    transform: translate(100%, -60%);
                }
            }
        }
    }
</style>